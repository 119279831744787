html,
body {
  background-color: #0a0a0a;
  margin: 0;
  padding: 0;
}

.image_full {
  display: block;
}

.image_mobile {
  display: none;
}

#container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.formabc {
  border-radius: 20px;
  max-width: calc(3 * 35vh);
  width: 100%;
  position: relative;
  margin: 5px auto;
  flex-grow: 1;
}

cf-input {
  background: none !important;
}

.responsive {
  max-width: 100%;
  height: auto;
  max-height: 55vh;
  margin: 0 auto;
}

.conversational-form {
  border-radius: 20px;
  margin: 10px !important;
}

.conversational-form:before {
  background: linear-gradient(
    to bottom,
    #2c2c2e 0,
    rgba(0, 0, 0, 0) 100%
  ) !important;
  border-radius: 20px 20px 0px 0px;
}

@media only screen and (max-width: 425px) {
  .image_full {
    display: none;
  }

  .image_mobile {
    display: block;
  }

  /* .formabc {
    max-width: 100%;
    height: 100%;
  } */

  .conversational-form {
    border-radius: 20px;
    margin: 10px 5px !important;
  }
}

